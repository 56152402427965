import React from "react";
import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { BlogCardsGrid } from "../components/blog/cards-grid";
import { BlogSignature } from "../components/blog/signature";
import { BlogImage } from "../components/blog/image";
import { BlogLayout } from "../components/blog/layout";
import { Divider } from "../components/core/divider/divider";
import MdxContent from "../components/mdx-content";
import { Box } from "../components/rebrand/core/box";
import { Grid } from "../components/rebrand/core/Grid/Grid";
import { Typography } from "../components/rebrand/core/Typography/Typography";
import { Section } from "../components/rebrand/sections/Section/Section";
import { SEO, SEOImage } from "../components/seo";

export const query = graphql`

  query MdxBlogPost($slug: String!) {

    site {
      siteMetadata {
        siteUrl
        pages {
          blog {
              title
              description
          }
        }
      }
    }
    
    latestFeaturedPosts: allMdx(
      filter: { frontmatter: { hidden: { eq: false }, featured: { eq: true } } }
      sort: { fields: frontmatter___publishDate, order: DESC }
      limit: 3
    ) {
      nodes {
        id
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        frontmatter {
          slug
          title
          subtitle
          author
          publishDate
          pinned
          featured
        }
      }
    }

    site {
      siteMetadata {
        pages {
          blog {
            title
            description
            keywords
          }
        }
      }
    }

    page : mdx( slug: { eq: $slug } ) {
      id
      body
      parent {
        ... on File{
          relativePath
        }
      }
      featuredImage {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 1024, placeholder: NONE)
        }
      }
      frontmatter {
        disabledToc
        author
        title
        date
        modDate
        tags
        category
				coverImage
      }
    }

  }
`


interface BlogPostTemplateProps extends PageProps {
  data: {
    latestFeaturedPosts: {
      nodes: {
        id: string;
        frontmatter: Queries.MdxFrontmatter;
        featuredImage: Queries.MdxFrontmatter;
      }[];
    };
    site: {
      siteMetadata: {
        siteUrl: string;
        pages: {
          blog: {
            title : string;
            description : string;
          }
        };
      };
    };
    page : {
      id: string;
      parent : {
        relativePath : string;
      }
      featuredImage: any;
      body:string;
      frontmatter : {
        disabledToc : boolean;
        subtitle: string;
        author : string;
        title : string;
        date : string;
        modDate : string;
        keywords: string;
        tags : object;
        category : string;
				coverImage : string
      }
    }
  };
}

export default function MdxBlogPostTemplate(props: BlogPostTemplateProps) {

  const {
    uri,
    data: { 
      page, 
      latestFeaturedPosts, 
      site 
    },
  } = props;

  const {
    siteUrl,
    pages: {
      blog: { description },
    },
  } = site.siteMetadata;

  const relativePath = 'dddd'

  const {
    title: pageTitle,
    author, 
    keywords,
    title, 
    subtitle,
    date, 
    modDate, 
    tags, 
    category,
    coverImage
  } = page.frontmatter;

  const featuredPosts = latestFeaturedPosts.nodes
    .filter((node) => node.id !== page.id)
    .map((node) => ({
      ... node.frontmatter,
      featuredImage : node.featuredImage
    }))
    .slice(0, 2);

  const seoImage = page.featuredImage.childImageSharp?.gatsbyImageData.images.fallback as SEOImage;

  const jsonld = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: true,
    headline: pageTitle,
    image: `${siteUrl}${seoImage?.src}`,
    url: uri,
    datePublished: date,
    description: description,
    author: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: siteUrl,
      name: "Polarycs",
      contactPoint: {
        "@type": "ContactPoint",
        email: "contacto@polarycs.com",
        contactType: "Atencion de clientes",
      },
    },
  };

  return (
    <BlogLayout currentPath={props.location.pathname}>
      <SEO
        title={pageTitle}
        description={subtitle ?? description}
        keywords={[...(keywords ?? [])]}
        author={author}
        image={seoImage}
        publishDate={date}
        pathname={props.location.pathname}
      > 
        <script type="application/ld+json">{JSON.stringify(jsonld)}</script> 
      </SEO>
      <Section data-sb-object-id={relativePath} paddingTopSpacing={[0, 0]} disableGutters={false}>
        <Grid>
          <BlogImage
            gridColumn="1 / span 12"
            marginTop={[0, 0, 3]}
            marginX={[-5, -7, -31]}
            borderRadius={["unset", "unset", 4]}
            image={page.featuredImage}
            alt={title}
          />
          <Box
            gridColumn={["1 / span 12", "1 / span 12", "2 / span 10"]}
            bg="background.base"
            borderRadius={3}
            paddingTopSpacing={5}
            paddingXSpacing={[0, 0, 11.5]}
            marginTopSpacing={[0, 0, -10.5]}
            zIndex={2}
          >
            <BlogSignature author={author as string} date={date as string} />
            <Box marginTop={5} marginBottom={[12, 10]}>
              <Typography variant="h1">{title}</Typography>
            </Box>
            <MdxContent>
              <MDXRenderer>{page.body}</MDXRenderer>
            </MdxContent>
          </Box>
          <Divider gridColumn="1 / span 12" />
          <Typography gridColumn="1 / span 12" variant="h3" marginYSpacing={[3, 3, 3.5]}>
            Otros post
          </Typography> 
          <Box gridColumn="1 / span 12">
            <BlogCardsGrid variant="regular" items={featuredPosts} />
          </Box>
        </Grid>
      </Section>
    </BlogLayout>
  );

}
